<template>
  <div id="supplier">
    <el-dialog
      :title="supplierFormTitle"
      width="1200px"
      :visible.sync="supplierDialogVisible"
      :close-on-click-modal="false"
      @close="supplierDialogClose"
    >
      <el-form
        ref="supplierFormRef"
        :model="supplierForm"
        :rules="supplierFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="供方名称" prop="supplierName">
              <el-input
                v-model="supplierForm.supplierName"
                placeholder="请输入供方名称"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地址" prop="address">
              <el-input
                v-model="supplierForm.address"
                placeholder="请输入地址"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业法人" prop="legalPerson">
              <el-input
                v-model="supplierForm.legalPerson"
                placeholder="请输入企业法人"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="质量负责人" prop="qualityManager">
              <el-input
                v-model="supplierForm.qualityManager"
                placeholder="请输入质量负责人"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电话" prop="telephone">
              <el-input
                v-model="supplierForm.telephone"
                placeholder="请输入电话"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人" prop="contactPerson">
              <el-input
                v-model="supplierForm.contactPerson"
                placeholder="请输入联系人"
                clearable
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="采购产品" prop="productList">
              <el-select
                v-model="supplierForm.productList"
                placeholder="请选择采购产品"
                clearable
                multiple
                filterable
                :disabled="!checkRole(['CAIGOU'])"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购部" prop="jjnl">
              <el-input
                v-model="supplierForm.jjnl"
                type="textarea"
                :rows="3"
                placeholder="供方简介及质量能力评价（附对其质量管理能力调查报告或体系认证证书及供方或其顾客提供的其他证明资料共    页）："
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['CAIGOU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量部" prop="scyp">
              <el-input
                v-model="supplierForm.scyp"
                type="textarea"
                :rows="3"
                placeholder="首次供货样品检测结果及结论（是否小批供货）：（A、B类物资需要）"
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['ZHILIANGSC'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购附件">
              <el-scrollbar style="height: 100px">
                <el-upload
                  ref="uploadCGRef"
                  action=""
                  :http-request="handleHttpCG"
                  :on-remove="handleRemoveCG"
                  :file-list="fileListCG"
                  multiple
                  :disabled="supplierFormTitle === '供应商详情' || !checkRole(['CAIGOU'])"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="!checkRole(['CAIGOU'])"
                  >
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量附件">
              <el-scrollbar style="height: 100px">
                <el-upload
                  ref="uploadZLRef"
                  action=""
                  :http-request="handleHttpZL"
                  :on-remove="handleRemoveZL"
                  :file-list="fileListZL"
                  multiple
                  :disabled="supplierFormTitle === '供应商详情' || !checkRole(['ZHILIANGSC'])"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="!checkRole(['ZHILIANGSC'])"
                  >
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产部" prop="xplsyxscb">
              <el-input
                v-model="supplierForm.xplsyxscb"
                type="textarea"
                :rows="3"
                placeholder="小批量试用加工适用性结果及结论：（A类物资需要）"
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['SHENGCHAN'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="技术部" prop="xplsyxjsb">
              <el-input
                v-model="supplierForm.xplsyxjsb"
                type="textarea"
                :rows="3"
                placeholder="小批量试用加工适用性结果及结论：（A类物资需要）"
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['JISHU'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质量部" prop="xpljcx">
              <el-input
                v-model="supplierForm.xpljcx"
                type="textarea"
                :rows="3"
                placeholder="小批量试用加工检测结果及结论：（A类物资需要）"
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['ZHILIANGXPL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管代" prop="pdjg">
              <el-input
                v-model="supplierForm.pdjg"
                type="textarea"
                :rows="3"
                placeholder="评定结论（是否列入合格供方名录）："
                :readonly="supplierFormTitle === '供应商详情'"
                :disabled="!checkRole(['GUANDAI'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总经理批准" prop="zjlpz">
              <el-radio-group v-model="supplierForm.zjlpz" :disabled="!checkRole(['ZJL'])">
                <el-radio :label="1">
                  同意
                </el-radio>
                <el-radio :label="2">
                  不同意
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="supplierDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="supplierFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      id="downloadTable"
      title="下载文件"
      width="500px"
      :visible.sync="downloadDialogVisible"
      :close-on-click-modal="false"
    >
      <el-table
        :data="downloadList"
        row-key="id"
        :cell-style="{padding: '6px'}"
        :header-cell-style="{background: '#f8f8f9'}"
      >
        <el-table-column prop="name" label="文件名称" />
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-download"
              size="small"
              @click.stop="handleDownload(scope.$index, scope.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="downloadDialogVisible = false">
          取 消
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="能力调查表"
      width="700px"
      :visible.sync="abilityDialogVisible"
      :close-on-click-modal="false"
      @close="abilityDialogClose"
    >
      <el-form
        ref="abilityFormRef"
        :model="abilityForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位名称" prop="dwmc">
              <el-input v-model="abilityForm.dwmc" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productList">
              <el-select
                v-model="abilityForm.productList"
                placeholder="请选择产品名称"
                multiple
                :disabled="true"
              >
                <el-option
                  v-for="item in abilityProductList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产能力" prop="scnl">
              <el-input v-model="abilityForm.scnl" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备状况" prop="sbzk">
              <el-input v-model="abilityForm.sbzk" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测能力" prop="jcnl">
              <el-input v-model="abilityForm.jcnl" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂房设施" prop="cfss">
              <el-input v-model="abilityForm.cfss" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质保能力" prop="zlbznl">
              <el-input v-model="abilityForm.zlbznl" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他方面" prop="qtfm">
              <el-input v-model="abilityForm.qtfm" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结论" prop="jl">
              <el-input v-model="abilityForm.jl" type="textarea" :rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调查时间" prop="dcsj">
              <el-date-picker v-model="abilityForm.dcsj" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="abilityDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="abilityFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="供方名称">
        <el-input v-model="searchForm.supplierName" placeholder="请输入供方名称" clearable />
      </el-form-item>
      <el-form-item label="供方代码">
        <el-input v-model="searchForm.supplierCode" placeholder="请输入供方代码" clearable />
      </el-form-item>
      <el-form-item label="企业法人">
        <el-input v-model="searchForm.legalPerson" placeholder="请输入企业法人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="supplierPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="supplierName" label="供方名称" />
      <el-table-column prop="address" label="地址" />
      <el-table-column prop="legalPerson" label="企业法人" />
      <el-table-column prop="telephone" label="电话/传真" />
      <el-table-column prop="contactPerson" label="联系人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-download"
            size="small"
            @click.stop="openDownload(scope.$index, scope.row)"
          >
            下载
          </el-button>
          <el-button
            type="text"
            icon="el-icon-document"
            size="small"
            @click.stop="handleAbility(scope.$index, scope.row)"
          >
            能力调查表
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="supplierPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSupplier,
  deleteSupplier,
  updateSupplierPurchase,
  updateSupplierQualityFirst,
  updateSupplierProduct,
  updateSupplierTechnology,
  updateSupplierQualityBatch,
  updateSupplierManagement,
  updateSupplierGeneral,
  selectSupplierInfo,
  selectSupplierList
} from '@/api/purchase/supplier'
import { selectProductList } from '@/api/purchase/product'
import { addAbility, updateAbility, selectAbilityInfo } from '@/api/purchase/ability'
import { upload, download } from '@/api/main/file'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      supplierDialogVisible: false,
      supplierFormTitle: '',
      supplierForm: {
        id: '',
        supplierName: '',
        address: '',
        legalPerson: '',
        qualityManager: '',
        telephone: '',
        contactPerson: '',
        productList: [],
        productId: '',
        jjnl: '',
        zslj: '',
        zsName: '',
        scyp: '',
        jcbgUrl: '',
        jcbgName: '',
        xplsyxscb: '',
        xplsyxjsb: '',
        xpljcx: '',
        pdjg: '',
        zjlpz: ''
      },
      fileListCG: [],
      fileListZL: [],
      supplierFormRules: {
        supplierName: [
          {
            required: true,
            message: '供方名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        address: [
          {
            required: true,
            message: '地址不能为空',
            trigger: ['blur', 'change']
          }
        ],
        legalPerson: [
          {
            required: true,
            message: '企业法人不能为空',
            trigger: ['blur', 'change']
          }
        ],
        productList: [
          {
            required: true,
            message: '采购产品不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      supplierPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        supplierName: '',
        supplierCode: '',
        legalPerson: ''
      },
      productList: [],
      downloadDialogVisible: false,
      downloadList: [],
      abilityDialogVisible: false,
      abilityFormTitle: '',
      abilityForm: {
        id: '',
        supplierId: '',
        dwmc: '',
        productList: [],
        productId: '',
        cpmc: '',
        scnl: '',
        sbzk: '',
        jcnl: '',
        cfss: '',
        zlbznl: '',
        qtfm: '',
        jl: '',
        dcsj: ''
      },
      abilityProductList: []
    }
  },
  created () {
    selectProductList().then(res => {
      this.productList = res.list
    })
    selectSupplierList(this.searchForm).then(res => {
      this.supplierPage = res
    })
  },
  methods: {
    supplierDialogClose () {
      this.$refs.uploadCGRef.clearFiles()
      this.$refs.uploadZLRef.clearFiles()
      this.$refs.supplierFormRef.resetFields()
      this.fileListCG = []
      this.fileListZL = []
    },
    supplierFormSubmit () {
      if (this.supplierFormTitle === '供应商详情') {
        this.supplierDialogVisible = false
        return
      }
      this.$refs.supplierFormRef.validate(async valid => {
        if (valid) {
          this.supplierForm.productId = this.supplierForm.productList.join(',')
          this.supplierForm.zslj = this.fileListCG.map(file => file.path).join(',')
          this.supplierForm.zsName = this.fileListCG.map(file => file.name).join(',')
          this.supplierForm.jcbgUrl = this.fileListZL.map(file => file.path).join(',')
          this.supplierForm.jcbgName = this.fileListZL.map(file => file.name).join(',')
          if (this.supplierFormTitle === '新增供应商') {
            await addSupplier(this.supplierForm)
          } else if (this.supplierFormTitle === '修改供应商') {
            if (this.checkRole(['CAIGOU'])) {
              await updateSupplierPurchase(this.supplierForm)
            }
            if (this.checkRole(['ZHILIANGSC'])) {
              await updateSupplierQualityFirst(this.supplierForm)
            }
            if (this.checkRole(['SHENGCHAN'])) {
              await updateSupplierProduct(this.supplierForm)
            }
            if (this.checkRole(['JISHU'])) {
              await updateSupplierTechnology(this.supplierForm)
            }
            if (this.checkRole(['ZHILIANGXPL'])) {
              await updateSupplierQualityBatch(this.supplierForm)
            }
            if (this.checkRole(['GUANDAI'])) {
              await updateSupplierManagement(this.supplierForm)
            }
            if (this.checkRole(['ZJL'])) {
              await updateSupplierGeneral(this.supplierForm)
            }
          }
          this.supplierPage = await selectSupplierList(this.searchForm)
          this.supplierDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.supplierFormTitle = '新增供应商'
      this.supplierDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSupplier(row.id)
        if (this.supplierPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.supplierPage = await selectSupplierList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.supplierFormTitle = '修改供应商'
      this.supplierDialogVisible = true
      selectSupplierInfo(row.id).then(res => {
        this.supplierForm.id = res.id
        this.supplierForm.supplierName = res.supplierName
        this.supplierForm.address = res.address
        this.supplierForm.legalPerson = res.legalPerson
        this.supplierForm.qualityManager = res.qualityManager
        this.supplierForm.telephone = res.telephone
        this.supplierForm.contactPerson = res.contactPerson
        this.supplierForm.productList = res.productList.map(item => item.id)
        this.supplierForm.jjnl = res.jjnl
        if (res.zslj) {
          const zslj = res.zslj.split(',')
          const zsName = res.zsName.split(',')
          this.fileListCG = []
          for (let i = 0; i < zslj.length; i++) {
            this.fileListCG.push({
              name: zsName[i],
              path: zslj[i]
            })
          }
        }
        this.supplierForm.scyp = res.scyp
        if (res.jcbgUrl) {
          const jcbgUrl = res.jcbgUrl.split(',')
          const jcbgName = res.jcbgName.split(',')
          this.fileListZL = []
          for (let i = 0; i < jcbgUrl.length; i++) {
            this.fileListZL.push({
              name: jcbgName[i],
              path: jcbgUrl[i]
            })
          }
        }
        this.supplierForm.xplsyxscb = res.xplsyxscb
        this.supplierForm.xplsyxjsb = res.xplsyxjsb
        this.supplierForm.xpljcx = res.xpljcx
        this.supplierForm.pdjg = res.pdjg
        this.supplierForm.zjlpz = res.zjlpz
      })
    },
    handleInfo (index, row) {
      this.supplierFormTitle = '供应商详情'
      this.supplierDialogVisible = true
      selectSupplierInfo(row.id).then(res => {
        this.supplierForm.id = res.id
        this.supplierForm.supplierName = res.supplierName
        this.supplierForm.address = res.address
        this.supplierForm.legalPerson = res.legalPerson
        this.supplierForm.qualityManager = res.qualityManager
        this.supplierForm.telephone = res.telephone
        this.supplierForm.contactPerson = res.contactPerson
        this.supplierForm.productList = res.productList.map(item => item.id)
        this.supplierForm.jjnl = res.jjnl
        if (res.zslj) {
          const zslj = res.zslj.split(',')
          const zsName = res.zsName.split(',')
          this.fileListCG = []
          for (let i = 0; i < zslj.length; i++) {
            this.fileListCG.push({
              name: zsName[i],
              path: zslj[i]
            })
          }
        }
        this.supplierForm.scyp = res.scyp
        if (res.jcbgUrl) {
          const jcbgUrl = res.jcbgUrl.split(',')
          const jcbgName = res.jcbgName.split(',')
          this.fileListZL = []
          for (let i = 0; i < jcbgUrl.length; i++) {
            this.fileListZL.push({
              name: jcbgName[i],
              path: jcbgUrl[i]
            })
          }
        }
        this.supplierForm.xplsyxscb = res.xplsyxscb
        this.supplierForm.xplsyxjsb = res.xplsyxjsb
        this.supplierForm.xpljcx = res.xpljcx
        this.supplierForm.pdjg = res.pdjg
        this.supplierForm.zjlpz = res.zjlpz
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSupplierList(this.searchForm).then(res => {
        this.supplierPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSupplierList(this.searchForm).then(res => {
        this.supplierPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSupplierList(this.searchForm).then(res => {
        this.supplierPage = res
      })
    },
    handleHttpCG (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileListCG.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemoveCG (file, fileList) {
      this.fileListCG = fileList
    },
    handleHttpZL (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileListZL.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemoveZL (file, fileList) {
      this.fileListZL = fileList
    },
    openDownload (index, row) {
      selectSupplierInfo(row.id).then(res => {
        this.downloadList = []
        let id = 1
        if (res.zslj) {
          const zslj = res.zslj.split(',')
          const zsName = res.zsName.split(',')
          for (let i = 0; i < zslj.length; i++) {
            this.downloadList.push({
              id: id++,
              name: zsName[i],
              path: zslj[i]
            })
          }
        }
        if (res.jcbgUrl) {
          const jcbgUrl = res.jcbgUrl.split(',')
          const jcbgName = res.jcbgName.split(',')
          for (let i = 0; i < jcbgUrl.length; i++) {
            this.downloadList.push({
              id: id++,
              name: jcbgName[i],
              path: jcbgUrl[i]
            })
          }
        }
        this.downloadDialogVisible = true
      })
    },
    handleDownload (index, row) {
      download({ fileName: row.path }).then(res => {
        saveAs(res, row.name)
      })
    },
    handleAbility (index, row) {
      this.abilityDialogVisible = true
      selectSupplierInfo(row.id).then(res => {
        this.abilityForm.supplierId = res.id
        this.abilityForm.dwmc = res.supplierName
        this.abilityProductList = res.productList
        this.abilityForm.productList = res.productList.map(item => item.id)
        this.abilityForm.productId = res.productList.map(item => item.id).join(',')
        this.abilityForm.cpmc = res.productList.map(item => item.name).join(',')
      })
      selectAbilityInfo(row.id).then(res => {
        if (res) {
          this.abilityForm.id = res.id
          this.abilityForm.scnl = res.scnl
          this.abilityForm.sbzk = res.sbzk
          this.abilityForm.jcnl = res.jcnl
          this.abilityForm.cfss = res.cfss
          this.abilityForm.zlbznl = res.zlbznl
          this.abilityForm.qtfm = res.qtfm
          this.abilityForm.jl = res.jl
          this.abilityForm.dcsj = res.dcsj
        }
      })
    },
    abilityDialogClose () {
      this.$refs.abilityFormRef.resetFields()
    },
    abilityFormSubmit () {
      if (this.abilityForm.id) {
        updateAbility(this.abilityForm)
      } else {
        addAbility(this.abilityForm)
      }
      this.abilityDialogVisible = false
    }
  }
}
</script>

<style>
#supplier .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
#supplier #downloadTable .el-dialog__body {
  padding: 0px 20px 20px 20px
}
</style>
