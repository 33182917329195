import axios from '@/common/axios'
import qs from 'qs'

export function addAbility (data) {
  return axios({
    method: 'post',
    url: '/ability/add',
    data: qs.stringify(data)
  })
}

export function updateAbility (data) {
  return axios({
    method: 'put',
    url: '/ability/update',
    data: qs.stringify(data)
  })
}

export function selectAbilityInfo (supplierId) {
  return axios({
    method: 'get',
    url: '/ability/info/' + supplierId
  })
}
